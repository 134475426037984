export default {
    AUTHORIZE(state, { token, admin }) {
        state.token = token;
        state.admin = admin;
        state.isAuthorized = true;
    },
    UN_AUTHORIZE(state) {
        state.token = "";
        state.admin = {};
        state.isAuthorized = false;
    },
    UPDATE_USER_INFO(state, admin) {
        state.admin = admin;
    },

    SET_PERMISSIONS(state, permissions) {
        state.permissions = permissions;
    },
};
