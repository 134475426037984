/*=========================================================================================
  File Name: index.js  Description: Routes for vue-index. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
import store from "../store/store";
import store2 from "store2";

Vue.use(Router);

const routes = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        };
    },
    routes: [
        {
            // =============================================================================
            // MAIN LAYOUT ROUTES
            // =============================================================================
            path: "",
            component: () => import("../layouts/main/Main.vue"),
            children: [
                // =============================================================================
                // Modules Routes
                // =============================================================================
                {
                    path: "/",
                    name: "home",
                    component: () => import("../views/Home.vue"),
                    meta: {
                        requiresAuth: true,
                        slug: "home"
                    }
                },
                {
                    path: "/brands",
                    name: "brands",
                    component: () =>
                        import("../views/modules/cars/brands/List"),
                    meta: {
                        requiresAuth: true,
                        slug: "brands"
                    }
                },
                {
                    path: "/car-types",
                    name: "car-types",
                    component: () =>
                        import("../views/modules/cars/car-types/List"),
                    meta: {
                        requiresAuth: true,
                        slug: "car-types"
                    }
                },
                {
                    path: "/adds-on",
                    name: "adds-on",
                    component: () =>
                        import("../views/modules/cars/adds-on/List"),
                    meta: {
                        requiresAuth: true,
                        slug: "add-on"
                    }
                },
                {
                    path: "/specs-categories",
                    name: "specs-categories",
                    component: () =>
                        import("../views/modules/cars/specs/categories/List"),
                    meta: {
                        requiresAuth: true,
                        slug: "specs-categories"
                    }
                },
                {
                    path: "/specs",
                    name: "specs",
                    component: () =>
                        import("../views/modules/cars/specs/specs/List"),
                    meta: {
                        requiresAuth: true,
                        slug: "specs"
                    }
                },
                {
                    path: "/bookings",
                    name: "bookings",
                    component: () => import("../views/modules/bookings/List"),
                    meta: {
                        requiresAuth: true,
                        slug: "bookings"
                    }
                },
                {
                    path: "/models",
                    name: "models",
                    component: () =>
                        import("../views/modules/cars/models/List"),
                    meta: {
                        requiresAuth: true,
                        slug: "models"
                    }
                },
                {
                    path: "/category-specs",
                    name: "category-specs",
                    component: () =>
                        import("../views/modules/cars/categories/specs/list"),
                    meta: {
                        requiresAuth: true,
                        slug: "category-specs"
                    }
                },
                {
                    path: "/services",
                    name: "services",
                    component: () =>
                        import("../views/modules/cars/services/List"),
                    meta: {
                        requiresAuth: true,
                        slug: "services"
                    }
                },
                {
                    path: "/models-create",
                    name: "models-create",
                    component: () =>
                        import("../views/modules/cars/models/Create"),
                    meta: {
                        requiresAuth: true,
                        slug: "models"
                    }
                },
                {
                    path: "/news",
                    name: "news",
                    component: () => import("../views/modules/News/List"),
                    meta: {
                        requiresAuth: true,
                        slug: "news"
                    }
                },
                {
                    path: "/about-us",
                    name: "about-us",
                    component: () =>
                        import("../views/modules/about-us/about-us.vue"),
                    meta: {
                        requiresAuth: true,
                        slug: "about-us"
                    }
                },
                {
                    path: "/rsa",
                    name: "rsa",
                    component: () => import("../views/modules/rsa/rsa.vue"),
                    meta: {
                        requiresAuth: true,
                        slug: "rsa"
                    }
                },
                {
                    path: "/rsa-requests",
                    name: "rsa-requests",
                    component: () =>
                        import("../views/modules/rsa/rsa-requests.vue"),
                    meta: {
                        requiresAuth: true,
                        slug: "rsa-requests"
                    }
                },
                {
                    path: "/mansourplus-informative",
                    name: "mansourplus-informative",
                    component: () =>
                        import(
                            "../views/modules/cars/mansour-plus/mansour-plus-info.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        slug: "mansourplus-info"
                    }
                },
                {
                    path: "/mansourplus-link",
                    name: "mansourplus-link",
                    component: () =>
                        import(
                            "../views/modules/cars/mansour-plus/mansour-plus-link.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        slug: "mansourplus-link"
                    }
                },
                {
                    path: "/trade-in",
                    name: "trade-in",
                    component: () =>
                        import("../views/modules/trade-in/trade-in.vue"),
                    meta: {
                        requiresAuth: true,
                        slug: "trade-in"
                    }
                },
                {
                    path: "/trade-in/settings",
                    name: "trade-in/settings",
                    component: () =>
                        import("../views/modules/trade-in/settings"),
                    meta: {
                        requiresAuth: true,
                        slug: "trade-in-settings"
                    }
                },
                {
                    path: "/spare-parts",
                    name: "spare-parts",
                    component: () =>
                        import(
                            "../views/modules/cars/spare-parts/spare-parts.vue"
                        ),
                    meta: {
                        requiresAuth: true,
                        slug: "spare-parts"
                    }
                },
                {
                    path: "/dashboard-signs",
                    name: "dashboard-signs",
                    component: () => import("../views/modules/signs/signs.vue"),
                    meta: {
                        requiresAuth: true,
                        slug: "dashboard-signs"
                    }
                },
                {
                    path: "/contact-us",
                    name: "contact-us",
                    component: () =>
                        import("../views/modules/contact-us/contact-us"),
                    meta: {
                        requiresAuth: true,
                        slug: "contact-us"
                    }
                },
                {
                    path: "/contact-us/settings",
                    name: "contact-us/seetings",
                    component: () =>
                        import("../views/modules/contact-us/settings"),
                    meta: {
                        requiresAuth: true,
                        slug: "contact-us-settings"
                    }
                },
                {
                    path: "/profile",
                    name: "profile",
                    component: () =>
                        import("../views/modules/profile/profile.vue"),
                    meta: {
                        requiresAuth: true,
                        slug: "profile"
                    }
                },
                {
                    path: "/galleries",
                    name: "galleries",
                    component: () => import("../views/modules/images/List"),
                    meta: {
                        requiresAuth: true,
                        slug: "galleries"
                    }
                },
                {
                    path: "/categories",
                    name: "categories",
                    component: () =>
                        import("../views/modules/cars/categories/List"),
                    meta: {
                        requiresAuth: true,
                        slug: "categories"
                    }
                },
                {
                    path: "/models/adds-on/:id",
                    name: "car-models-adds-on",
                    component: () =>
                        import("../views/modules/cars/models/add-addons"),
                    meta: {
                        requiresAuth: true,
                        slug: "models"
                    }
                },
                {
                    path: "/categories/show/:id",
                    name: "car-categories-show",
                    component: () =>
                        import(
                            "../views/modules/cars/categories/show-category"
                        ),
                    meta: {
                        requiresAuth: true,
                        slug: "categories"
                    }
                },
                {
                    path: "/categories/add-specs/:id",
                    name: "car-categories-add-spec",
                    component: () =>
                        import(
                            "../views/modules/cars/categories/specs/add-spec"
                        ),
                    meta: {
                        requiresAuth: true,
                        slug: "categories"
                    }
                },
                {
                    path: "/models/add-service/:id",
                    name: "car-models-add-service",
                    component: () =>
                        import("../views/modules/cars/models/add-service"),
                    meta: {
                        requiresAuth: true,
                        slug: "models"
                    }
                },
                {
                    path: "/categories/add-service/:modelID/:categoryID",
                    name: "car-categories-add-service",
                    component: () =>
                        import(
                            "../views/modules/cars/categories/services/add-service"
                        ),
                    meta: {
                        requiresAuth: true,
                        slug: "models"
                    }
                },
                {
                    path: "/models/:id",
                    name: "car-modele-view",
                    component: () =>
                        import("../views/modules/cars/models/viewItem"),
                    meta: {
                        requiresAuth: true,
                        slug: "models"
                    }
                },
                {
                    path: "/models/edit-service/:id/:serviceId",
                    name: "car-models-edit-service",
                    component: () =>
                        import("../views/modules/cars/models/edit-service"),
                    meta: {
                        requiresAuth: true,
                        slug: "models"
                    }
                },
                {
                    path: "/categories/edit-service/:id/:serviceId",
                    name: "car-categories-edit-service",
                    component: () =>
                        import(
                            "../views/modules/cars/categories/services/edit-service"
                        ),
                    meta: {
                        requiresAuth: true,
                        slug: "models"
                    }
                },
                {
                    path: "/categories/add-accessory/:id",
                    name: "car-models-add-accessory",
                    component: () =>
                        import(
                            "../views/modules/cars/categories/accessories/add-accessory"
                        ),
                    meta: {
                        requiresAuth: true,
                        slug: "categories"
                    }
                },
                {
                    path: "/categories/edit-accessory/:id/:accessoryId",
                    name: "car-models-edit-accessory",
                    component: () =>
                        import(
                            "../views/modules/cars/categories/accessories/edit-accessory"
                        ),
                    meta: {
                        requiresAuth: true,
                        slug: "categories"
                    }
                },
                {
                    path: "/branches/",
                    name: "branches",
                    component: () => import("../views/modules/branches/List"),
                    meta: {
                        requiresAuth: true,
                        slug: "branches"
                    }
                },
                {
                    path: "/branches/add",
                    name: "add-branches",
                    component: () =>
                        import("../views/modules/branches/form"),
                    meta: {
                        requiresAuth: true,
                        slug: "branches"
                    }
                },
                {
                    path: "/models/copy-service/:id/:serviceId",
                    name: "car-models-copy-service",
                    component: () =>
                        import("../views/modules/cars/models/copy-service"),
                    meta: {
                        requiresAuth: true,
                        slug: "models"
                    }
                },
                {
                    path: "/categories/copy-service/:id/:serviceId",
                    name: "car-categories-copy-service",
                    component: () =>
                        import(
                            "../views/modules/cars/categories/services/copy-service"
                        ),
                    meta: {
                        requiresAuth: true,
                        slug: "models"
                    }
                },
                {
                    path: "/rsa/settings",
                    name: "rsa-settings",
                    component: () => import("../views/modules/rsa/settings"),
                    meta: {
                        requiresAuth: true,
                        slug: "rsa-settings"
                    }
                },
                {
                    path: "/extended-warranty",
                    name: "extended-warranty",
                    component: () =>
                        import("../views/modules/extended-warranty/List"),
                    meta: {
                        requiresAuth: true,
                        slug: "extended-warranty"
                    }
                },
                {
                    path: "/extended-warranties/:id",
                    name: "show-extended-warranty",
                    component: () =>
                        import(
                            "../views/modules/extended-warranty/show-extended"
                        ),
                    meta: {
                        requiresAuth: true,
                        slug: "extended-warranty"
                    }
                },
                {
                    path: "/extended-warranty/add-contents/:id",
                    name: "add-extended-warranty-contents",
                    component: () =>
                        import(
                            "../views/modules/extended-warranty/add-extended-content"
                        ),
                    meta: {
                        requiresAuth: true,
                        slug: "extended-warranty"
                    }
                },
                {
                    path: "/extended-warranty/update-content/:id",
                    name: "add-extended-warranty-contents",
                    component: () =>
                        import(
                            "../views/modules/extended-warranty/edit-extended-content"
                        ),
                    meta: {
                        requiresAuth: true,
                        slug: "extended-warranty"
                    }
                },
                {
                    path: "/aplus",
                    name: "aplus",
                    component: () => import("../views/modules/aplus/List.vue"),
                    meta: {
                        requiresAuth: true,
                        slug: "aplus"
                    }
                },
                {
                    path: "/aplus-video",
                    name: "aplus-video",
                    component: () =>
                        import("../views/modules/aplus/upload-video.vue"),
                    meta: {
                        requiresAuth: true,
                        slug: "aplus-video"
                    }
                },
                {
                    path: "/test-drive",
                    name: "test-drive",
                    component: () => import("../views/modules/test-drive/List"),
                    meta: {
                        requiresAuth: true,
                        slug: "test-drive"
                    }
                },
                {
                    path: "/home-services",
                    name: "home-services",
                    component: () =>
                        import("../views/modules/home-services/settings"),
                    meta: {
                        requiresAuth: true,
                        slug: "home-services"
                    }
                },
                {
                    path: "/test-drive-settings",
                    name: "test-drive-settings",
                    component: () =>
                        import("../views/modules/test-drive/settings"),
                    meta: {
                        requiresAuth: true,
                        slug: "test-drive-settings"
                    }
                },
                {
                    path: "/users",
                    name: "users",
                    component: () => import("../views/modules/users/List.vue"),
                    meta: {
                        requiresAuth: true,
                        slug: "users"
                    }
                },
                {
                    path: "/notifications",
                    name: "notifications",
                    component: () =>
                        import("../views/modules/notifications/List.vue"),
                    meta: {
                        requiresAuth: true,
                        slug: "notifications"
                    }
                },
                {
                    path: "/contact-us/inquiries",
                    name: "contact-us/inquiries",
                    component: () =>
                        import("../views/modules/contact-us/inquiries.vue"),
                    meta: {
                        requiresAuth: true,
                        slug: "contact-us-inquiries"
                    }
                },
                {
                    path: "/installment",
                    name: "installment",
                    component: () =>
                        import(
                            "../views/modules/installment-calculator/settings"
                        ),
                    meta: {
                        requiresAuth: true,
                        slug: "installment"
                    }
                },
                {
                    path: "/bookings/settings",
                    name: "booking/settings",
                    component: () =>
                        import("../views/modules/bookings/settings"),
                    meta: {
                        requiresAuth: true,
                        slug: "booking-settings"
                    }
                },
                {
                    path: "/add-test-drive",
                    name: "add-test-drive",
                    component: () =>
                        import("../views/modules/test-drive/add-test-drive"),
                    meta: {
                        requiresAuth: true,
                        slug: "test-drive"
                    }
                },
                {
                    path: "/edit-test-drive/:id",
                    name: "edit-test-drive",
                    component: () =>
                        import("../views/modules/test-drive/edit-test-drive"),
                    meta: {
                        requiresAuth: true,
                        slug: "test-drive"
                    }
                },
                {
                    path: "/show-test-drive/:id",
                    name: "show-test-drive",
                    component: () =>
                        import("../views/modules/test-drive/show-test-drive"),
                    meta: {
                        requiresAuth: true,
                        slug: "test-drive"
                    }
                },
                {
                    path: "/show-test-drive-request/:id",
                    name: "show-test-drive-request",
                    component: () =>
                        import("../views/modules/test-drive/view-request"),
                    meta: {
                        requiresAuth: true,
                        slug: "test-drive"
                    }
                },
                {
                    path: "/admins",
                    name: "admins",
                    component: () => import("../views/modules/admins/list"),
                    meta: {
                        requiresAuth: true,
                        slug: "admins"
                    }
                },
                {
                    path: "/requests-logs",
                    name: "requests-logs",
                    component: () =>
                        import("../views/modules/requests-logs/list"),
                    meta: {
                        requiresAuth: false,
                        slug: "requests-logs"
                    }
                },
                {
                    path: "/hotlines",
                    name: "hotlines",
                    component: () =>
                        import("../views/modules/hotlines/hotlines.vue"),
                    meta: {
                        requiresAuth: true,
                        slug: "hotlines"
                    }
                },
                {
                    path: "/servers",
                    name: "servers",
                    component: () => import("../views/modules/servers/list"),
                    meta: {
                        requiresAuth: true,
                        slug: "servers"
                    }
                },
            ]
        },
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: "",
            component: () => import("@/layouts/full-page/FullPage.vue"),
            children: [
                // =============================================================================
                // PAGES
                // =============================================================================
                {
                    path: "/login",
                    name: "page-login",
                    component: () => import("@/views/Login.vue"),
                    meta: {
                        requiresAuth: false
                    }
                },
                {
                    path: "/error-404",
                    name: "page-error-404",
                    component: () => import("@/views/Error404.vue"),
                    meta: {
                        requiresAuth: false
                    }
                },
                {
                    path: "/error-403",
                    name: "page-error-403",
                    component: () => import("@/views/Error403.vue"),
                    meta: {
                        requiresAuth: false
                    }
                }
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: "*",
            redirect: "/error-404"
        }
    ]
});

routes.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});

routes.beforeEach((to, from, next) => {
    let perms = typeof store2.get("permissions") == "string" ? JSON.parse(store2.get("permissions")) : store2.get("permissions");
    const permissions = perms;
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters["auth/isLoggedIn"]) {
            if (permissions.find(o => o.title === to.meta.slug)) {
                console.log("Access Granted");
                next();
                return;
            } else {
                console.log("Access Denied");
                next("/error-403");
                return;
            }
        }
        next("/login");
    } else {
        if (store.getters["auth/isLoggedIn"] && to.name === "login") {
            if (permissions.find(o => o.title === to.meta.slug)) {
                console.log("Access Granted");
                next("/");
                return;
            } else {
                console.log("Access Denied");
                next("/error-403");
            }
        } else {
            next();
            return;
        }
    }
});

export default routes;
