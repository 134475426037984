import axios from "axios";

const baseURL = process.env.VUE_APP_BASE_URL;
const carsMicroServicesURL = process.env.VUE_APP_MICRO_SERVICES_URL;
const adminURL = process.env.VUE_APP_ADMIN_URL;
const newsMicroServicesURL = process.env.VUE_APP_MICRO_SERVICES_NEWS_URL;
const signsURL = process.env.VUE_APP_MICRO_SERVICES_DASHBOARD_URL;
const branchesURL = process.env.VUE_APP_MICRO_SERVICES_BRANCHES_URL;

export const http = axios.create({
    baseURL: baseURL
});

export const httpSigns = axios.create({
  baseURL: signsURL
});

export const httpCars = axios.create({
    baseURL: carsMicroServicesURL,
    headers: {
      // 'Access-Control-Allow-Origin': '*',
    }
});

export const httpAdmin = axios.create({
    baseURL: adminURL,
  headers: {
      'Accept-Language': 'en'
  }
});

export const httpNews = axios.create({
    baseURL: newsMicroServicesURL
});

export const httpAbout = axios.create({
    baseURL: baseURL,
    headers: {
        "Content-Type": "application/json"
    }
});

export const httpSignsDashboard = axios.create({
    baseURL: signsURL,
    headers: {
        "Content-Type": "application/json"
    }
});

export const httpBranches = axios.create({
  baseURL: branchesURL
});
