/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import store2 from "store2";
import App from "./App.vue";
// Dotenv
import { config } from "dotenv";
// axios
import {
    http,
    httpAPI,
    httpAbout,
    httpAdmin,
    httpBranches,
    httpCars,
    httpNews,
    httpSigns,
    httpSignsDashboard
} from "./plugins/axios.js";
// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
// Theme Configurations
import "../themeConfig.js";
// Globally Registered Components
import "./globalComponents.js";
// Styles: SCSS
import "./assets/scss/main.scss";
// Tailwind
import "@/assets/css/main.css";
// Vue Router
import index from "./routes";
// Vuex Store
import store from "./store/store";
// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
// Vee Validate
import VeeValidate from "vee-validate";
import CKEditor from "@ckeditor/ckeditor5-vue";
// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

config();

Vue.prototype.$http = http;
Vue.prototype.$httpCars = httpCars;
Vue.prototype.$httpNews = httpNews;
Vue.prototype.$httpAbout = httpAbout;
Vue.prototype.$httpSignsDashboard = httpSignsDashboard;
Vue.prototype.$httpAdmin = httpAdmin;
Vue.prototype.$httpBranches = httpBranches;
Vue.prototype.$httpSigns = httpSigns;

const token = store2.get("token");
if (token) {
    Vue.prototype.$http.defaults.headers.common["Authorization"] =
        "Bearer " + token;
}

Vue.use(Vuesax);

Vue.use(VueHammer);

Vue.use(VeeValidate);

Vue.use(CKEditor);

// Feather font icon
require("./assets/css/iconfont.css");

Vue.config.productionTip = false;

Vue.use(require("vue-moment"));

Vue.component("vue-multiselect", window.VueMultiselect.default);

new Vue({
    router: index,
    store,
    render: h => h(App)
}).$mount("#app");
