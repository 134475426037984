import store2 from "store2";
import { http } from "../../plugins/axios";

export default {
    login({ commit }, payload) {
        return new Promise((resolve, reject) => {
            http({
                url: "/auth/login",
                data: payload,
                method: "POST"
            })
                .then(resp => {
                    const token = resp.data.data.token;
                    const admin = resp.data.data.admin.name;
                    store2.set("admin", admin);
                    store2.set("token", token);
                    http.defaults.headers.common["Authorization"] =
                        "Bearer " + token;
                    commit("AUTHORIZE", { token, admin });
                    resolve(resp.data);
                })
                .catch(err => {
                  console.log({Error: err});
                    commit("UN_AUTHORIZE");
                    store2.remove("token");
                    reject(err.response.data.error);
                });
        });
    },
    logout({ commit }) {
        return new Promise(resolve => {
            commit("UN_AUTHORIZE");
            store2.remove("token");
            delete http.defaults.headers.common["Authorization"];
            resolve();
        });
    },
  setPermissions({commit}, payload){
      commit('SET_PERMISSIONS', payload);
  }
};
