import store2 from "store2";

export default {
  isAuthorized: false,
  token: store2.get("token") || "",
  admin: {},
  model_id: '',
  services: {},
  permissions: []
};
